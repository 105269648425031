$link: #dddddd;

@import "bulma/sass/utilities/_all.sass";
@import "bulma/sass/base/minireset.sass";
@import "bulma/sass/base/helpers.sass";
@import "bulma/sass/base/generic.sass";
@import "bulma/sass/grid/columns.sass";
@import "bulma/sass/layout/hero.sass";
@import "bulma/sass/layout/footer.sass";
@import "bulma/sass/elements/container.sass";
@import "bulma/sass/elements/title.sass";

html,body {
  font-family: 'Open Sans', serif;
}
.hero.is-black {
  background: linear-gradient(
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ), url('https://source.unsplash.com/random/1280x720') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
.hero .nav, .hero.is-success .nav {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.hero .subtitle {
  padding: 3rem 0;
  line-height: 1.5;
}

a {
  text-decoration: underline;
}
